export const ProjectData=[
    {
        id:1,
        title:"InfraMon",
        about: "Infra-Mon or Infrastructure Monitoring is a metric ingestion server. It records the cpu and memory percentage utilization against the IP address of the client in-memory.",
        tags:["ReactJS", "Javascript", "Firebase", "Html/Css"],
        demo:"",
        github:"https://github.com/ibreakthecloud/infra-mon",
        image:"",
    },
    {
        id:2,
        title:"nullhash/kcm",
        about: "Kube Config Manager, is a CLI tool that helps users manage multiple clusters by mapping the kubectl to the desired config file.",
        tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
        demo:"",
        github:"https://github.com/nullhash/kcm",
        image:"",
    },
    {
        id:3,
        title:"ContactBook",
        about: "Contact book is an go based contact server that allows to create contact with name and email, where email is unique.",
        tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
        demo:"",
        github:"https://github.com/ibreakthecloud/contact-book",
        image:"",
    },
    {
        id:4,
        title:"Gql-payload",
        about: "",
        tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
        demo:"",
        github:"https://github.com/ibreakthecloud/gql-payload",
        image:"",
    },
    {
        id:5,
        title:"AJAX-Chat",
        about: "This is a ramdom project I am using to React.JS,Javascript, HTML/CSS.This is portfolio website and I hope you are enjoying this portfolio.Make sure you like and comment if you have any doubt",
        tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
        demo:"",
        github:"https://github.com/ibreakthecloud/AJAX-Chat",
        image:"",
    },
    {
        id:6,
        title:"IOPS Plugin",
        about: "Scope IOWait plugin is a Go application that uses iostat to provide host-level CPU IO wait or idle metrics in the Weave Scope UI.",
        tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
        demo:"",
        github:"https://github.com/ibreakthecloud/iops-plugin",
        image:"",
    },
    {
        id:7,
        title:"Enigmail",
        about: "A Single Page Application, an emailing client made using PHP and AngularJS.",
        tags:["ReactJS", "Javascript", "Firebase", "Portfolio"],
        demo:"",
        github:"https://github.com/ibreakthecloud/Enigmail",
        image:"",
    },
];